import React from "react"
import MainLayout from "../components/mainLayout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import contact from '../images/contact.jpg'
import { useStaticQuery, graphql } from "gatsby"
import style from "./contact.module.scss"

const ContactPage = () => {
  const content = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/contact/"}}
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `)

  function createMarkup() {
    return {__html: content.allMarkdownRemark.edges[0].node.html};
  }

  return (
    <MainLayout>
      <SEO title="Contact" />
      <Banner primaryText={'Contact'} backgroundImg={contact}/>
      <div className={style.contactBody}>
        <div className={style.contactCenterColumn}>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </div>
      </div>
    </MainLayout>
  )
}

export default ContactPage
